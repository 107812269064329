import React from "react"
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Markdown from "react-markdown"

import Layout from "../components/layout"

import { headline, selfPortrait, aboutText } from "./about.module.css"

const AboutPage = (props) => {
  const data = useStaticQuery(query)
  const aboutData = data.strapiAbout
  return (
    <Layout seo={aboutData.seo} page={"/about"}>
    <h1 className={headline}>{aboutData.headline}</h1>
    <GatsbyImage
      image={aboutData.aboutImage.file.childImageSharp.gatsbyImageData}
      className={selfPortrait}
      backgroundColor="#f6f6f6"
      fadeIn={true}
      alt={aboutData.aboutImage.alternativeText}
      title={aboutData.aboutImage.caption} />
    <div className={aboutText}>
      <Markdown className="body-regular" children={aboutData.text} escapeHtml={false} />
      <p class="body-regular action-text"><a href={`mailto:${aboutData.email}`}>inquiries</a></p>
    </div>
  </Layout>
  )
}

export default AboutPage

export const query = graphql`{
  strapiAbout {
    headline
    text
    email
    aboutImage {
      alternativeText
      caption
      file {
        publicURL
        childImageSharp {
          gatsbyImageData(width: 300, placeholder: NONE, layout: CONSTRAINED)
        }
      }
    }
    seo {
      title
      description
      shareImage {
        alternativeText
        file {
          publicURL
          childImageSharp {
            gatsbyImageData(width: 660)
          }
        }
      }
    }
  }
}
`

